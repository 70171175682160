import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import ReadMoreLink from '../general/ReadMoreLink'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import PostImage from '../../components/articles/PostImage'

const EXCERPT_MAX_LENGTH = 480

const getExcerpt = (post, length = EXCERPT_MAX_LENGTH) => {
  const { body = '', excerpt } = post

  if (excerpt) return `${excerpt.substring(0, length)}...`
  return `${body.substring(0, length)}...`
}

const ArticleCard = ({
  post,
  author,
  excerptLength,
  hideExcerpt,
  className,
  index,
  titleStyle,
  showFeaturedImage,
  showReadMoreLink = false,
}) => {
  const excerpt = getExcerpt(post, excerptLength)
  const classes = className || 'column is-full is-half-desktop'
  const borderClasses = index % 2 === 0 ? 'has-border' : ''
  const featuredClasses = post.isFeatured ? 'is-featured' : ''
  const titleClassNames = titleStyle || 'content is-size-5 has-text-link'

  const track = (action) => () => {
    trackCustomEvent({
      category: 'ArticleCard',
      action,
    })
  }

  const hasImage = post.image && post.image.url
  const hasAuthorImage =
    post.author && post.author.image && post.author.image.url
  const showImage = (hasImage || hasAuthorImage) && showFeaturedImage

  return (
    <div
      className={`column article-card margin-bottom-20 ${classes} ${borderClasses} ${featuredClasses} `}
    >
      <div>
        <Link
          onClick={track('titleClick')}
          className={titleClassNames}
          to={`/${post.slug}`}
        >
          {post.title}
        </Link>
        <p className="content is-small">
          {author && (
            <Link
              onClick={track('authorClick')}
              className="has-text-link"
              to={`/authors/${author.screenName}`}
            >
              {author.name}
            </Link>
          )}
        </p>
      </div>

      {!hideExcerpt && (
        <div className="columns">
          <div className={`column ${showImage ? 'is-7' : 'is-12'}`}>
            <p className="excerpt has-text-justified">
              {excerpt}
              {showReadMoreLink && (
                <div onClick={track('readmoreClick')}>
                  <ReadMoreLink link={post.slug} />
                </div>
              )}
            </p>
          </div>
          {showImage && (
            <div className="column is-5 has-text-centered">
              <a onClick={track('titleClick')} href={`/${post.slug}`}>
                <PostImage post={post} />
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

ArticleCard.propTypes = {
  post: PropTypes.shape({
    isFeatured: PropTypes.bool,
    slug: PropTypes.string,
    title: PropTypes.string.isRequired,
    author: PropTypes.shape({
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    screenName: PropTypes.string.isRequired,
  }).isRequired,
  excerptLength: PropTypes.number,
  titleStyle: PropTypes.string,
  showFeaturedImage: PropTypes.bool,
  showReadMoreLink: PropTypes.bool,
  hideExcerpt: PropTypes.bool,
  className: PropTypes.string,
  index: PropTypes.number,
}

export default ArticleCard
