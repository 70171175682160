import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import ArticleCard from './ArticleCard'

const NewsRoll = ({
  data,
  hideExcerpt,
  excerptLength,
  titleStyle = 'content is-size-5 has-text-link',
  count,
}) => {
  const { allStrapiArticle: { edges: posts = [] } = {} } = data
  const postsToShow = count ? posts.slice(0, count) : posts

  return (
    <>
      {postsToShow &&
        postsToShow.map(({ node: post }, i) => (
          <ArticleCard
            key={post.id}
            className="news-card column is-full"
            index={i}
            author={post.author}
            post={post}
            hideExcerpt={hideExcerpt}
            excerptLength={excerptLength}
            titleStyle={titleStyle}
          />
        ))}
    </>
  )
}

const NewsArticleRoll = (props) => (
  <StaticQuery
    query={graphql`
      query NewsRoll {
        allStrapiArticle(
          limit: 100
          sort: { order: [DESC], fields: [publishDate] }
          filter: { category: { categorySlug: { eq: "أخبار" } } }
        ) {
          edges {
            node {
              excerpt
              id
              isFeatured
              publishDate
              strapiId
              slug
              title
              updated_at
              created_at
              body
            }
          }
        }
      }
    `}
    render={(data, count) => <NewsRoll data={data} count={count} {...props} />}
  />
)

NewsRoll.propTypes = {
  data: PropTypes.shape({
    allStrapiArticle: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  hideExcerpt: PropTypes.bool,
  excerptLength: PropTypes.number,
  titleStyle: PropTypes.string,
  count: PropTypes.number,
}

export default NewsArticleRoll
